import React, { useState, useRef, useEffect } from "react";
import { Button, Input } from 'reactstrap';
import moment from 'moment';

const ChatBox = (props) => {
  const { activeKey, messages, setMessages, setActiveKey, allKeys, user, chatMessagesEndRef, isRequestRejected } = props;
  const [newMessage, setNewMessage] = useState("");


  const handleSendMessage = () => {
    if (!newMessage.trim()) return;

    setMessages(prevMessages => {
      const updatedMessages = { ...prevMessages };
      if (!updatedMessages[activeKey]) {
        updatedMessages[activeKey] = [];
      }
      updatedMessages[activeKey].push({
        text: newMessage,
        sender: user.uid,
        timestamp: moment().format('YYYY-MM-DD HH:mm:ss'),
        senderEmail: user.email,
      });
      return updatedMessages;
    });
    setNewMessage("");
  };

  const renderMessages = () => {
    return messages[activeKey]?.map((msg, index) => {
      const isSender = msg.sender === user.uid;
      return (
        <div
          key={index}
          className={`chat-message ${isSender ? 'sent' : 'received'}`}
        >
          <span>{msg.text}</span>
          <div className="message-time">
            {moment(msg.timestamp).format('MMM D, YYYY h:mm A')}
          </div>
        </div>
      );
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <div
      className="chatbox-container"
    >
      <div className="chatbox-header">
        <img 
          className="chatbox-close-btn" 
          onClick={() => setActiveKey(null)}
          src={require("../assets/icons/cross-red.png")}/>
        <p className="chat-title">{allKeys.find(a => a.key === activeKey)?.label}</p>
      </div>
      <div className="chat-messages">
        {renderMessages()}
        <div ref={chatMessagesEndRef} />
      </div>
      {isRequestRejected && <p className="rejected-request">Change request rejected</p>}
      <div className="chat-input">
        <Input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Type your message..."
          className="chat-input-field"
          onKeyDown={handleKeyDown} 
          disabled={isRequestRejected}
        />
        <Button
          color="primary"
          onClick={handleSendMessage}
          className="chat-send-button"
          disabled={isRequestRejected}
        >
          <img src={require("../assets/icons/send.png")}/>
        </Button>
      </div>
    </div>
  );
};

export default ChatBox;
