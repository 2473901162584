import React, { useState, useEffect, useRef } from "react";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import { toast } from "react-toastify";
import moment from "moment";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label, Input, Row, Col } from 'reactstrap';
import ChatBox from "./ChatBox";

const RequestChangeModal = (props) => {
  const { showModal, hideModal, offer, currentSeller, user, refetchOfferData } = props;
  const [activeKey, setActiveKey] = useState(null); 
  const [messages, setMessages] = useState({});
  const [chatPosition, setChatPosition] = useState({ top: 0, left: 0 });
  const [modalVisible, setModalVisible] = useState(false);
  const [approvedChanges, setApprovedChanges] = useState({});
  const [rejectedRequests, setRejectedRequests] = useState([]);
  const [currentKey, setCurrentKey] = useState(null);
  const [newValue, setNewValue] = useState({});
  const chatMessagesEndRef = useRef(null);
  console.log(newValue,"newValue")
  const includedKeys = [
    { key: "offerType", label: "Offer Type" },
    { key: "bac", label: "Buyers Agent Compensation" },
    { key: "purchasePrice", label: "Purchase Price" },
    { key: "financingType", label: "Financing Type" },
    { key: "downPayment", label: "Down Payment" },
    { key: "financeAmount", label: "Finance Amount" },
    { key: "sellerConcessions", label: "Seller Concession" },
    { key: "earnestMoney", label: "Earnest Money" },
    { key: "residentialServiceContract", label: "Service Contract" },
    { key: "optionFee", label: "Option Fee" },
    { key: "optionDays", label: "Option Days" },
    { key: "titlePolicyExpense", label: "Title Policy Expense" },
    { key: "closingDate", label: "Closing Date" },
    { key: "numberOfDays", label: "Number of Days" },      
    { key: "possession", label: "Possession" },
    { key: "leasebackDays", label: "Leaseback Days" },
    { key: "nonRealtyItem", label: "Non Realty Item" },
    { key: "rightToTerminate", label: "Right To Terminate Due to Lender’s Appraisal" },
    { key: "offer_expire_date", label: "Offer Expire Date" },
    { key: "agentName", label: "Agent Name" },
    { key: "licenseNo", label: "Agent License No" },
    { key: "saleOfOtherProperty", label: "Sale of Other Property" },
    { key: "brokerageName", label: "Brokerage Name" },
    { key: "documents", label: "Documents" },
    { key: "buyerName", label: "Buyer Name" },
    { key: "buyerPhoneNumber", label: "Buyer Phone Number" }
  ];

  const filteredKeys = user.uid !== offer.seller_id 
    ? includedKeys.filter(item => messages[item.key]?.length > 0)
    : includedKeys;

  useEffect(() => {
    if (chatMessagesEndRef.current) {
      chatMessagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages, activeKey]);

  useEffect(() => {
    if (offer?.request_change?.requests) {
      const initialMessages = {};
      const initialRejectedRequests = [];

      Object.keys(offer.request_change.requests).forEach((key) => {
        const request = offer.request_change.requests[key];
        if (request.messages) {
          initialMessages[key] = request.messages.map((msg) => ({
            text: msg.text,
            sender: msg.sender,
            senderEmail: msg.senderEmail,
            timestamp: msg.timestamp,
          }));
        }
        if (request.status === "Rejected") {
          initialRejectedRequests.push(key); 
        }
      });

      setMessages(initialMessages);
      setRejectedRequests(initialRejectedRequests);
    }
  }, [offer]);
  console.log(JSON.stringify(rejectedRequests), "rejectedRequests")
  const renderValue = (key, value) => {
    if (value === null || value === undefined) return "N/A";
  
    if (key === "purchasePrice" || key === "earnestMoney" || key === "downPayment" || key === "financeAmount" || key === "sellerConcessions" || key === "residentialServiceContract" || key === "optionFee") {
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value);
    }
  
    if (key === "documents" && Array.isArray(value)) {
      return (
        <ul style={{ listStyleType: "disc", paddingLeft: "20px", fontSize: "14px" }}>
          {value.map((doc, index) => (
            <li key={index}>
              <a href={doc.path} target="_blank" rel="noopener noreferrer">
                <strong>{doc.name}</strong> - 
                <span style={{ fontSize: 12 }}>
                  {new Date(doc.timestamp).toLocaleDateString()}
                </span>
              </a>  
            </li>
          ))}
        </ul>
      );
    }
  
    if (Array.isArray(value)) {
      return value
        .map((item) =>
          typeof item === "object"
            ? Object.entries(item)
                .map(([k, v]) => `${k}: ${v}`)
                .join(", ")
            : item
        )
        .join(" | ");
    }
  
    if (typeof value === "object") {
      return JSON.stringify(value);
    }
  
    if (key === "bac" && offer?.bac_type === "Percentage") {
      return value.includes("%") ? value : `${value}%`;
    }
  
    return value.toString();
  };

  const handleQuestionClick = (key, event) => {
    setActiveKey(key);
    const rect = event.target.getBoundingClientRect();
    setChatPosition({ top: rect.top + window.scrollY + 30, left: rect.left + window.scrollX });
  };

  const hasMessages = (key) => {
    return messages[key] && messages[key].length > 0;
  };

  const handleSubmitChangeRequest = async () => {
    const requestChange = {
      status: "In Progress",
      createdAt: moment().unix(),
      requests: {},
    };

    includedKeys.forEach((item) => {
      const { key } = item;
      if (messages[key]) {
        let status = rejectedRequests.includes(key) ? "Rejected" : "In Progress";
        let changeHistory = [];
        if (approvedChanges.hasOwnProperty(key)) {
          status = "Approved";
          changeHistory.push({
            updatedValue: approvedChanges[key],
            oldValue: offer[key],
            timestamp: moment().format("YYYY-MM-DD HH:mm:ss"),
            updatedBy: user.uid,
          });
        }
        requestChange.requests[key] = {
          status,
          messages: messages[key].map((msg) => ({
            text: msg.text,
            timestamp: msg.timestamp,
            sender: msg.sender,
            senderEmail: msg.senderEmail,
          })),
        };
        if (changeHistory.length > 0) {
          requestChange.requests[key].changeHistory = changeHistory;
        }
      }
    });

    try {
      const db = firebase.firestore();
      await db.collection("offers").doc(offer.id).update({
        "request_change": requestChange,
      });

      toast.success("Change request has been successfully submitted.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      sendNotification(offer);
      refetchOfferData();
      hideModal();
    } catch (error) {
      toast.error("Error in submitting the change request.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

   const sendNotification = async (offer) => {
     const statusWords = {
       "In Progress": "updated",
       "Accepted": "accepted",
       "Rejected": "rejected",
       // Add more statuses here as needed
     };
 
     try {
       // Send Email (using Firebase Functions or directly)
       const sendEmail = firebase.functions().httpsCallable("sendMatchEmail");
       const emailBody = `Your change request has been submitted. Please review it.`;
 
       await sendEmail({
         subject: `Change Request for Offer ${offer.id}`,
         email: offer.buyerEmail,
         body: emailBody,
       });
 
       const sendSMS = firebase.functions().httpsCallable("sendSMS");
       await sendSMS({
         to: `+1${offer.phoneNumber}`,
         body: `Change request submitted for your offer. Please review the details.`,
       });
 
       // Send Firestore notification
       const db = firebase.firestore();
       const notificationText = `Your offer has been updated by ${offer.agentName || "the agent"}. Please review the details.`;
 
       await db.collection("notifications").add({
         user_id: offer.buyer, // Targeting the buyer for this notification
         property_id: offer.seller, // Seller associated with this offer
         text: notificationText,
         type: "offer",
         isRead: 0, // Notification unread
         createdAt: moment().utc().unix(), // Timestamp
       });
 
     } catch (error) {
       console.error("Error sending notification:", error);
     }
   };
  
   const handleRejectRequest = (key) => {
    const label = includedKeys.find(item => item.key === key)?.label || "this request";
    if (window.confirm(`Are you sure you want to reject the proposed change for ${label}?`)) {
      setRejectedRequests(prev => [...prev, key]);
      console.log(`Rejecting change for: ${label}`);
    }
  };

  const handleApproveRequest = (key) => {
    const value = offer[key];
    setCurrentKey(key);
    setNewValue(value || ""); 
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  const handleAcceptChanges = () => {
    if (window.confirm("Are you sure you want to proceed with this change?")) {
      setApprovedChanges(prev => ({ ...prev, [currentKey]: newValue }));
      setNewValue("");
      setRejectedRequests(prev => prev.filter(item => item !== currentKey));
      setModalVisible(false);
    }
  };
  console.log(JSON.stringify(approvedChanges), "approvedChanges")
  return (
    <>
      <Modal
        className="seller-net-modal-body"
        // scrollable={true}
        isOpen={showModal}
        toggle={() => {
          setActiveKey(null);
          hideModal();
        }}
        size="lg"
      >
        <ModalHeader toggle={() => {
          setActiveKey(null);
          hideModal();
        }}>{user.uid === offer.seller_id ? "Propose Changes" : "Review Changes"}</ModalHeader>
        <ModalBody>
          <Row>
            <Col className="p-0">
            <p className="seller-net-sheet-description">
            {
              user.uid === offer.seller_id ? "Review the buyer’s offer and propose changes to negotiate terms that align with your goals." :
            "Review the seller’s proposed changes and update your offer to continue the negotiation process."
            }
            </p>
              {offer && (
                <div className="req-change-container">
                  {filteredKeys.map((item, index) => {
                    const { key, label } = item;
                    const isLastItem = index === filteredKeys.length - 1;
                    const iconSrc = hasMessages(key)
                      ? require("../assets/icons/question-colored.png")
                      : require("../assets/icons/question.png");
                    const approved = offer?.request_change?.requests &&
                      offer.request_change.requests[key] &&
                      offer.request_change.requests[key].status === "Approved";
                    const isRejected = rejectedRequests.includes(key);  
                    return (
                      <div
                        key={key}
                        className="d-flex justify-content-between align-items-center"
                        style={{
                          borderBottom: isLastItem ? "none" : "2px solid #fff",
                        }}
                      >
                         <span>
                          <strong>{label}:</strong>{" "}
                          {approved
                            ? renderValue(key, offer.request_change.requests[key].changeHistory?.[0]?.updatedValue)
                            : renderValue(key, offer[key])}
                        </span>
                        <div className="relative">
                          {!approved && (
                            <img
                              src={iconSrc}
                              alt="question icon"
                              className="question-icon"
                              onClick={(event) => handleQuestionClick(key, event)}
                            />
                          )}
                          {user.uid !== offer.seller_id && !isRejected && !approved && (
                            <>
                              <img
                                src={require("../assets/icons/approve.png")}
                                alt="approve icon"
                                className="question-icon"
                                onClick={() => handleApproveRequest(key)}
                              />
                              <img
                                src={require("../assets/icons/reject.png")}
                                alt="reject icon"
                                className="question-icon"
                                onClick={() => handleRejectRequest(key)}
                              />
                            </>
                          )}
                          {isRejected && (
                            <img
                              src={require("../assets/icons/warning.png")}
                              alt="warning icon"
                              className="question-icon"
                              title={`${user.uid === offer.seller_id ? "Buyer" : "You"} rejected this change request`}
                            />
                          )}
                          {approved && (
                            <span style={{ fontWeight: "bold", color: "green" }}>Approved</span>
                          )}
                          {activeKey === key && (
                            <ChatBox
                              activeKey={activeKey}
                              messages={messages}
                              setMessages={setMessages}
                              setActiveKey={setActiveKey}
                              chatPosition={chatPosition}
                              allKeys={filteredKeys}
                              user={user}
                              isRequestRejected={isRejected}
                              chatMessagesEndRef={chatMessagesEndRef}
                            />
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="seller-net-footer">
          <Button 
            color="secondary" 
            onClick={() => {
              setActiveKey(null);
              hideModal();
            }}>
            Cancel
          </Button>
          <Button
            className="settle-btn"
            onClick={handleSubmitChangeRequest}
          >
            Submit Change Request
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalVisible} toggle={handleModalClose}>
        <ModalHeader toggle={handleModalClose}>Approve Change</ModalHeader>
        <ModalBody>
          <p style={{ marginTop: 20 }}>
            The current value for <strong>{currentKey}</strong> is displayed below.
            Please modify it as needed so that the deal can be closed.
          </p>
          {currentKey === "closingDate" || currentKey === "offer_expire_date" ? (
            <Input
              type="date"
              value={newValue ? moment(newValue).format("YYYY-MM-DD") : ""}
              onChange={(e) => setNewValue(e.target.value)}
            />
          ) : (
            <Input
              type="text"
              value={newValue || ""}
              onChange={(e) => setNewValue(e.target.value)}
              placeholder="Enter new value"
            />
          )}
        </ModalBody>
        <ModalFooter className="seller-net-footer">
          <Button color="secondary" onClick={handleModalClose}>Cancel</Button>
          <Button className="settle-btn" onClick={handleAcceptChanges}>Change Value</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default RequestChangeModal;